h1 {
  padding: 0;
  margin: 0;
  font-family: sans-serif;
}
.wrapper-glitch {
  margin-top: 2em;
  margin-bottom: 2em;
  display: flex;
  width: 100%;
  align-content: flex-start;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  @media only screen and (max-width: 750px) {
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 2em;
  }
}
.sub {
  color: #ff4a54;
  letter-spacing: 1em;
}

@mixin glitchCopy {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.eng {
  color: #fff;
}
.jp {
  color: #64ffda;
}
.glitch {
  position: relative;
  font-size: 4em;
  letter-spacing: 0.5em;
  animation: glitch-skew 1s infinite linear alternate-reverse;
  margin-right: 0.5em;
  @media only screen and (max-width: 1200px) {
    margin-right: 0;
    font-size: 3em;
  }
  &::before {
    @include glitchCopy;
    left: 2px;
    text-shadow: -2px 0 #ff00c1;
    clip: rect(44px, 450px, 56px, 0);
    animation: glitch-anim 5s infinite linear alternate-reverse;
  }

  &::after {
    @include glitchCopy;
    left: -2px;
    text-shadow: -2px 0 #ff00c1, 2px 2px #ff00c1;
    clip: rect(44px, 450px, 56px, 0);
    animation: glitch-anim2 5s infinite linear alternate-reverse;
  }
}

@keyframes glitch-anim {
  $steps: 15;

  @for $i from 0 to $steps {
    #{percentage($i*(1/$steps))} {
      clip: rect(random(100) + px, 9999px, random(100) + px, 0);
      transform: skew((random(100) / 100) + deg);
    }
  }
}

@keyframes glitch-anim2 {
  $steps: 20;

  @for $i from 0 to $steps {
    #{percentage($i*(1/$steps))} {
      clip: rect(random(100) + px, 9999px, random(100) + px, 0);
      transform: skew((random(100) / 100) + deg);
    }
  }
}

@keyframes glitch-skew {
  $steps: 10;

  @for $i from 0 to $steps {
    #{percentage($i*(1/$steps))} {
      transform: skew((random(10) - 5) + deg);
    }
  }
}
