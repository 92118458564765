.menu{
    width: 300px;
    height: 100vh;
    background-color: burlywood;
    position: fixed;
    top: 0;
    right: -300px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 2s ease;


    &.active{
        right: 0;
    }

    ul{
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 35px;
        font-weight: 300;
        color: black;
        width: 60%;

        li{
            margin-bottom: 25px;
            a{
                font-size: inherit;
                color: inherit;
                text-decoration: none;
            }

            &:hover {
                font-weight: 1000;
            }
        }
    }
}