.topbar{
    width: 100%;
    height: 70px;
    background-color: #09192f;
    color: black;
    position: fixed;
    top: 0;
    z-index: 3;
    transition: all 1s ease;
    overflow: hidden;
    .wrapper{
        animation: fadeInDown 1.5s;
        padding: 10px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 65.6px;
        
        .left{
            width: 50%;
            margin-left: -1%;
            @media screen and (max-width: 900px) {
                margin-left: -3em;
            }
            .logo{
                margin-left: 2%;
                @media screen and (max-width: 900px) {
                    margin-left: 1em;
                }
                background-color: transparent;
                font-size: 35px;
                font-weight: 700;
                text-decoration: none;
                color: #64ffda;
                animation: neon 1s ease-in-out infinite alternate;
            }
                        @keyframes neon {
                            from {
                                text-shadow:
                                    0 0 1px rgba(202, 228, 225, 0.92),
                                    0 0 7px rgba(202, 228, 225, 0.34),
                                    0 0 3px rgba(100, 255, 218, 0.52),
                                    0 0 6px rgba(100, 255, 218, 0.92),
                                    0 0 8px rgba(100, 255, 218, 0.78),
                                    0 0 14px rgba(100, 255, 218, 0.92);
                            }
            
                            to {
                                text-shadow:
                                    0 0 1px rgba(202, 228, 225, 0.98),
                                    0 0 7px rgba(202, 228, 225, 0.42),
                                    0 0 3px rgba(30, 132, 242, 0.58),
                                    0 0 5px rgba(30, 132, 242, 0.84),
                                    0 0 9px rgba(30, 132, 242, 0.88),
                                    0 0 15px rgba(30, 132, 242, 1);
                            }
                        }
        }
        
        .right { 
            .menuText{
                font-size: 15px;
                text-decoration: none;
                color: #ccd6f6;
                margin-left: 1.5em;
                font-family: monospace;
                &:hover {
                color: #64ffda;
                }
                .kanji{
                color: #64ffda;
                }
                @media screen and (max-width: 900px) {
                    display: none;
                }
            }
            
            .btn{
                @media screen and (max-width: 900px) {
                    display: none;
                }
                margin-left: 3em;
                .menuTextbtn {
                        font-size: 15px;
                        text-decoration: none;
                        font-family: monospace;
                        color: #64ffda;
                    }
            } 
            .poki{
                @media screen and (min-width: 900px) {
                    display: none;
                }
                color: #64ffda;
            } 
           
        }
    }
}